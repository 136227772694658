import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import {
  PageHome
} from "./pages";

class Routes extends Component {
  
  render() {
    return (
      <Switch>
        <Route sensitive  exact path="/" component={PageHome}>
          <PageHome />
        </Route>
        {/* <Route sensitive  path="/tvdashboard" component={PageDashboardTV}>
          <PageDashboardTV />
        </Route> */}
        
      </Switch>
    );
  }
}

export default Routes;
