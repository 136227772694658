import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {/* ======= Footer ======= */}
      <footer id="footer" className="footer">
            <div className="container">
              <div className="row gy-4">
                <div className="col-lg-5 col-md-12 footer-info">
                  <Link to="/" className="logo d-flex align-items-center">
                    <span>UNSUB</span>
                  </Link>
                  <p>Universitas Subang (Unsub) merupakan Universitas pertama yang ada di kabupaten Subang, memiliki 7 Fakultas dan 14 Program Studi (prodi) dengan jenjang pendidikan S2, S1 dan D3. Terdiri atas 2 (dua) kampus, yakni Kampus I dan Kampus II.</p>
                  <div className="social-links d-flex mt-4">
                    <a href={"https://www.youtube.com/@unsub.official"} className="youtube"><i className="bi bi-youtube" /></a>
                    <a href={"https://www.facebook.com/unsubhumas/"} className="facebook"><i className="bi bi-facebook" /></a>
                    <a href={"https://www.instagram.com/unsub.official/?hl=id"} className="instagram"><i className="bi bi-instagram" /></a>
                  </div>
                </div>
                <div className="col-lg-2 col-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><a href={"https://daftar.unsub.ac.id/"}>Tahapan PMB</a></li>
                    <li><a href={"https://daftar.unsub.ac.id/"}>FAQ ( pertanyaan seputar PMB )</a></li>
                  </ul>
                </div>
                <div className="col-lg-2 col-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li><a href={"https://siakad.unsub.ac.id/"}>Sistem Informasi Akademik</a></li>
                    <li><a href={"https://elearning.unsub.ac.id/"}>Learning Management System</a></li>
                    <li><a href={"https://daftar.unsub.ac.id/"}>Sistem PMB</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                  <h4>Contact Us</h4>
                  <p>
                    Jalan R.A Kartini, KM 03 Subang <br />
                    Subang, Jawabarat 41211<br />
                    Indonesia <br /><br />
                    <strong>Phone / WA : </strong>+62 857-2394-6700<br />
                    <strong>Email : </strong>info@unsub.ac.id<br />
                  </p>
                </div>
              </div>
            </div>
            <div className="container mt-4">
              <div className="copyright">
                © Created With Love by <strong><span>Puskom Team</span></strong>. All Rights Reserved
              </div>
              <div className="credits">
                Designed by Puskom
              </div>
            </div>
          </footer>{/* End Footer */}
          {/* End Footer */}

    </div>
  );
};

export default Footer;
