import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./Routes";
//import $ from "jquery";


class App extends Component {
  constructor() {
    super();
    this.state = {
      sessionToken: "",
    };
  }


//  componentWillMount() {
//     let login_public = localStorage.getItem("login_public");
//     if (login_public && !this.state.sessionToken) {
//       this.setState({
//         sessionToken: login_public,
//       });
//     }
//   }

//   setSessionState = (login_public) => {
//     localStorage.setItem("login_public", login_public);
//     this.setState({ sessionToken: login_public });
//   };




  render() {
    return (
      <Router>
        <Routes />
      </Router>
    );
  }
}

export default App;
