import React, { Component } from "react";
import { Link } from "react-router-dom";
// import $ from "jquery";


class Header extends Component {
  render() {
    return (
      <div>

        {/* ======= Header ======= */}
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
              <Link to="/" className="logo d-flex align-items-center">
                <img src={"assets/img/logo.png"} alt="true" />
                <h1>PMB 2023</h1>
              </Link>
              <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
              <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
              <nav id="navbar" className="navbar">
                <ul>
                  <li><Link to="/" className="active">Home</Link></li>
                  <li><a className="btnsignup" href={"https://daftar.unsub.ac.id/"}><i className="fas fa-fw fa-user-graduate" />&nbsp;&nbsp;Daftar</a></li>
                </ul>
              </nav>{/* .navbar */}
            </div>
          </header>{/* End Header */}
          {/* End Header */}

      </div>
    );
  }
}

export default Header;
