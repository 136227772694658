import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";


class Main extends Component {
  render() {
   // const children = this.props;
    return (
      <div>
        <HelmetProvider>
          <Helmet>
          <title>PMB UNIVERSITAS SUBANG</title>
          </Helmet>
        </HelmetProvider>
        <Header />
          {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default Main;
