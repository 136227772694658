import React, { Component } from "react";
//import { Link } from "react-router-dom";
import Main from "../../layout/Main";
//import ProgressBar from 'react-bootstrap/ProgressBar';
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

class PageHome extends Component {
  // state = { modalIsOpen: false }
  // toggleModal = () => {
  //   this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  // }
  render() {
    //const { modalIsOpen } = this.state;
    return (
      <Main>
        <div>
          
          {/* ======= Hero Section ======= */}
          <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
              <div className="row gy-4 d-flex justify-content-between">
                <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h2 data-aos="fade-up">Penerimaan Mahasiswa Baru</h2>
                  <p data-aos="fade-up" data-aos-delay={100}>Universitas Subang (Unsub) merupakan Universitas pertama yang ada di kabupaten Subang, memiliki 7 Fakultas dan 14 Program Studi (prodi) dengan jenjang pendidikan S2, S1 dan D3. Terdiri atas 2 (dua) kampus, yakni Kampus I dan Kampus II.</p>
                  <div className="d-flex gy-4" data-aos="fade-up" data-aos-delay={400}>
				    <a href={"assets/doc/BROSUR-PMB-2023.pdf"} className="btn btn-info me-2" download><i className="fas fa-fw fa-download" /> Download Brosur PMB</a>
                    <a href={"https://daftar.unsub.ac.id/"} className="btn btn-warning"><i className="fas fa-fw fa-user-graduate" /> Daftar Sekarang</a>
                  </div>
                </div>
                <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                  <img src={"assets/img/cover_pmb.JPG"} className="img-fluid mb-3 mb-lg-0 rounded-5" alt="true" />
                </div>
              </div>
            </div>
          </section>{/* End Hero Section */}
          <main id="main">
            <br />
            {/* ======= Services Section ======= */}
            <section id="service" className="services pt-0" style={{zIndex: 1}}>
              <div className="container" data-aos="fade-up">
                <div className="section-header">
                  <h2>Our Faculty</h2>
                </div>
                <div className="row gy-4">
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={100}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/FAPERTA.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://agrorektan.unsub.ac.id/"} className="stretched-link">Fakultas Agrobisnis dan Rekayasa Pertanian</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/FASILKOM.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://fasilkom.unsub.ac.id/"} className="stretched-link">Fakultas Ilmu Komputer</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/FIA.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://fia.unsub.ac.id/"} className="stretched-link">Fakultas Ilmu Administrasi</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={400}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/FIKOM.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://fikom.unsub.ac.id/"} className="stretched-link">Fakultas Ilmu Komunikasi</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={500}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/FKIP.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://fkip.unsub.ac.id/"} className="stretched-link">Fakultas Keguruan dan Ilmu Pendidikan</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={600}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/HUKUM.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://hukum.unsub.ac.id/"} className="stretched-link">Fakultas Hukum</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={600}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/TEKNIK.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://teknik.unsub.ac.id/"} className="stretched-link">Fakultas Teknik</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                  <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={600}>
                    <div className="card">
                      <div className="card-img">
                        <img src={"assets/img/fakultas/MAP.jpg"} alt="true" className="img-fluid" />
                      </div>
                      <h3><a href={"https://www.map.unsub.ac.id/"} className="stretched-link">Magister Administrasi Publik</a></h3>
                      <ul />
                    </div>
                  </div>{/* End Card Item */}
                </div>
              </div>
            </section>{/* End Services Section */}
            {/* ======= About Us Section ======= */}
            <section id="about" className="about">
              <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                  <div className="col-lg-6 content order-last  order-lg-last" style={{paddingLeft: 15}}>
                    <h3>Keuntungan Kuliah di Universitas Subang</h3>
                    <p>
                      Terdapat program Reguler dan Karyawan untuk mahasiswa
                    </p>
                    <ul>
                      <li data-aos="fade-up" data-aos-delay={100}>
                        <i className="bi bi-diagram-3" />
                        <div>
                          <h5>Terdapat beasiswa bagi mahasiswa berprestasi</h5>
                          <p>Akan diberikan beasiswa yang bersumber dari Yayasan Kutawaringin, Provinsi Jawa Barat, Bidik Misi, BNI, BRI, PT. Taekwang Industrial, Pemerintah Kabupaten Subang, Beasiswa Peningkatan Prestrasi Akademik dari LLDIKTI</p>
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay={200}>
                        <i className="bi bi-fullscreen-exit" />
                        <div>
                          <h5>Fasilitas-fasilitas yang tersedia</h5>
                          <p>Lab Komputer, Lab Bahasa, Radio Kampus, Studio Musik, Koperasi Mahasiswa, Internet dan Wifi, Lab Pertanian, Lab Teknik, Sarana Olah Raga, Masjid Kampus, Kantin, Kantor Kas BJB, ATM BJB, Galeri Investasi Bursa Efek</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 position-relative align-self-start order-lg-fisrt order-first">
                    <img src={"assets/img/cover_pmb.jpeg"} className="img-fluid" alt="true" style={{borderRadius: 25}} />
                    <a href={"https://youtu.be/0JHt34Os7Rc"} className="glightbox play-btn" >&nbsp;</a>
                  </div>
                </div>
              </div>
            </section>{/* End About Us Section */}
            {/* ======= Call To Action Section ======= */}
            <section id="call-to-action" className="call-to-action">
              <div className="container" data-aos="zoom-out">
                <div className="row justify-content-center">
                  <div className="col-lg-8 text-center">
                    <h3>Come and Join With US</h3>
                    <p> Mari Gabung Bersama kami.</p>
                    <a className="cta-btn" href={"https://daftar.unsub.ac.id/"}>Daftar Sekarang</a>
                  </div>
                </div>
              </div>
            </section>{/* End Call To Action Section */}
          </main>{/* End #main */}
          

        </div>
      </Main>
    );
  }
}

export default PageHome;
